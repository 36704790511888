import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const AboutContent = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);
    return (
        <section aria-label="section" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 jarallax" data-jarallax-element="-20">
                        <p className="lead big wow fadeInUp">
                            Immerse yourself in the ambience of our thoughtfully designed space, where modern aesthetics merge harmoniously with classNameic elements. We believe that a barbershop should be more than just a place to get a haircut; it should be a sanctuary where you can unwind, engage in great conversation, and leave feeling invigorated.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutContent;

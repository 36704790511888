import React from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ServicesPricing = () => {
    return (
        <section className="section-pricing">
            <div className="container">
                <div className="row">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 768: 1, 991: 2 }}>
                        <Masonry gutter="48px">
                            <div className="sc-wrap">
                                <h3>Haircut</h3>
                                <div className="def-list-dots">
                                    <dl>
                                        <dt>
                                            <span>Regular Haircut</span>
                                        </dt>
                                        <dd>$37</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Scissors Haircut</span>
                                        </dt>
                                        <dd>$40</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Kids Haircut</span>
                                        </dt>
                                        <dd>$30</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="sc-wrap">
                                <h3>Shave</h3>
                                <div className="def-list-dots">
                                    <dl>
                                        <dt>
                                            <span>Head Shave</span>
                                        </dt>
                                        <dd>$27</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Royal Shave</span>
                                        </dt>
                                        <dd>$33</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Royal Head Shave</span>
                                        </dt>
                                        <dd>$33</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Beard Trim No Shave</span>
                                        </dt>
                                        <dd>$35</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Beard Trim Shave</span>
                                        </dt>
                                        <dd>$35</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Beard Shave Up</span>
                                        </dt>
                                        <dd>$30</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="sc-wrap">
                                <h3>Facial</h3>
                                <div className="def-list-dots">
                                    <dl>
                                        <dt>
                                            <span>Deep Pore Cleansing</span>
                                        </dt>
                                        <dd>$50</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Aromatherapy Facial</span>
                                        </dt>
                                        <dd>$45</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Acne Problem Facial</span>
                                        </dt>
                                        <dd>$60</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>European Facial</span>
                                        </dt>
                                        <dd>$50</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Glycolic Peel Facial</span>
                                        </dt>
                                        <dd>$35</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="sc-wrap">
                                <h3>Package</h3>
                                <div className="def-list-dots">
                                    <dl>
                                        <dt>
                                            <span>Haircut + Shave</span>
                                        </dt>
                                        <dd>$50</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Haircut + Beard Trim</span>
                                        </dt>
                                        <dd>$50</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Haircut + Beard Trim Shave</span>
                                        </dt>
                                        <dd>$55</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <span>Haircut + Beard Shape Up</span>
                                        </dt>
                                        <dd>$60</dd>
                                    </dl>
                                </div>
                            </div>
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </section>
    );
}

export default ServicesPricing;

import React from 'react';

const ContactInfo = () => {

    const ContactData = [
        {
            icon: 'fa-solid fa-location-dot de-icon gradient de-hover-scale text-dark mb20',
            heading: 'Our Address',
            content: '5-Indraprasth Nagar, Above Kasturi Super Market, Nr. Satnam Hospital, Amin Marg Rajkot - 360001, Gujarat',
        },
        {
            icon: 'fa-solid fa-phone de-icon gradient de-hover-scale text-dark mb20',
            heading: 'Phone Number',
            content: '+91 94086 20201',
        },
        {
            icon: 'fa-regular fa-envelope de-icon gradient de-hover-scale text-dark mb20',
            heading: 'Email Address',
            content: 'thecuttingbar2021@gmail.com',
        },
    ];

    return (
        <section id="section-content" className="no-top">
            <div className="container">
                <div className="row">
                    {ContactData.map((item, index) => (
                        <div className="col-lg-4 col-md-4 text-center med-m30" key={index}>
                            <i className={item.icon}></i>
                            <p className="lead no-bottom">{item.heading}</p>
                            <h4 className="s2">{item.content}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ContactInfo;

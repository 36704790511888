import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';
import { NavLink } from 'react-router-dom';

const OurTeam = () => {

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    const OurTeamData = [
        {
            count: '-20',
            img: 'assets/images/team/1.jpg',
            name: 'Steven Porreca',
            facebook: '',
            twitter: '',
            instagram: '',
        },
        {
            count: '-60',
            img: 'assets/images/team/2.jpg',
            name: 'Huey Apicella',
            facebook: '',
            twitter: '',
            instagram: '',
        },
        {
            count: '-40',
            img: 'assets/images/team/3.jpg',
            name: 'Harry Riecke',
            facebook: '',
            twitter: '',
            instagram: '',
        },
        {
            count: '-10',
            img: 'assets/images/team/4.jpg',
            name: 'Merilyn Axe',
            facebook: '',
            twitter: '',
            instagram: '',
        },
    ];

    return (
        <section aria-label="section" className="no-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="wow fadeIn">Our Team</h2>
                        <div className="de-separator"></div>
                    </div>
                    {OurTeamData.map((item, index) => (
                        <div className="col-lg-3 text-center jarallax" key={index} data-jarallax-element={item.count}>
                            <div className="de-box-a">
                                <div className="d-image">
                                    <img src={item.img} alt="Team" />
                                </div>
                                <div className="d-deco-1"></div>
                                <div className="d-deco-2"></div>
                                <div className="d-social">
                                    <NavLink to={item.facebook} target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                    <NavLink to={item.twitter} target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                    <NavLink to={item.instagram} target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                                </div>
                            </div>
                            <div className="spacer-20"></div>
                            <h4>{item.name}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default OurTeam;

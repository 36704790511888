import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const ContactForm = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);
    return (
        <section className="no-top jarallax">
            <div className="de-gradient-edge-top"></div>
            <img src="assets/images/background/7.jpg" className="jarallax-img" alt="" />
            <div className="container position-relative z1000">
                <div className="row gx-5">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="d-sch-table">
                            <h2 className="wow fadeIn text-center">Message Us</h2>
                            <div className="de-separator"></div>
                            <p className="lead text-center">
                                If you got any questions, please do not hestitae to send us a message.
                            </p>
                            <form id="contact_form" className="form-border position-relative z1000" method="post" action="#">
                                <div className="row">
                                    <div className="col-lg-12 mb10">
                                        <div className="field-set">
                                            <input type="text" name="Name" id="name" className="form-control" placeholder="Your Name" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb10">
                                        <div className="field-set">
                                            <input type="text" name="Email" id="email" className="form-control" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mb10">
                                        <div className="field-set">
                                            <input type="text" name="phone" id="phone" className="form-control" placeholder="Your Phone" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="field-set mb20">
                                    <textarea name="message" id="message" className="form-control" placeholder="Your Message" required></textarea>
                                </div>
                                <div className="text-center">
                                    <div className="g-recaptcha" data-sitekey="6LdW03QgAAAAAJko8aINFd1eJUdHlpvT4vNKakj6" align="center"></div>
                                    <div id='submit' className="mt20">
                                        <input type='submit' id='send_message' value='Send Message' className="btn-main" />
                                    </div>
                                </div>
                                <div id="success_message" className='success'>
                                    Your message has been sent successfully. Refresh this page if you want to send more messages.
                                </div>
                                <div id="error_message" className='error'>
                                    Sorry there was an error sending your form.
                                </div>
                            </form>
                            <div className="d-deco"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
        </section>
    );
}

export default ContactForm;

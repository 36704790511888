import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const ServicesName = () => {
    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    const ServicesData = [
        {
            count: '-20',
            img: 'assets/images/services/1.jpg',
            name: 'Haircuts',
        },
        {
            count: '-60',
            img: 'assets/images/services/2.jpg',
            name: 'Beard',
        },
        {
            count: '-40',
            img: 'assets/images/services/3.jpg',
            name: 'Shaving',
        },
        {
            count: '-10',
            img: 'assets/images/services/4.jpg',
            name: 'Razor Blade',
        },
    ];

    return (
        <section className="no-top">
            <div className="container">
                <div className="row">
                    {ServicesData.map((item, index) => (
                        <div className="col-lg-3 text-center jarallax" key={index} data-jarallax-element={item.count}>
                            <div className="de-box-a">
                                <div className="d-image">
                                    <img src={item.img} alt="Sevices" />
                                </div>
                                <div className="d-deco-1"></div>
                                <div className="d-deco-2"></div>
                            </div>
                            <div className="spacer-20"></div>
                            <h4>{item.name}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServicesName;

import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';

const HomeContent = () => {
    
jarallaxElement();

useEffect(() => {
    jarallax(document.querySelectorAll('.jarallax'));
}, []);
    return (
        <section aria-label="section" className="no-top no-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 jarallax" data-jarallax-element="-50">
                        <p className="lead big wow fadeInUp">
                            Established with a passion for the art of barbering, we take great pride in our craft and strive to create an atmosphere that feels like home. From the moment you walk through our doors, you'll be greeted by friendly smiles and a warm ambiance that instantly puts you at ease.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeContent;

import React from "react";
import { NavLink } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const TestimonialsBox = () => {

    const TestimonialsData = [
        {
            review: 'I had an amazing experience at the barbershop! The atmosphere was friendly and inviting, and the staff was very professional. The barber took the time to listen to what I wanted and gave me an excellent haircut that exceeded my expectations.',
            img: 'assets/images/people/1.jpg',
            name: 'Elliot Richbourg',
        },
        {
            review: 'I ve been going to this barbershop for years, and I wouldn t trust anyone else with my hair. The barbers are skilled and knowledgeable, and they always keep up with the latest trends and techniques. The shop is always clean and well-maintained, and they take hygiene seriously, which is especially important these days. I leave feeling refreshed and confident after every visit. Highly recommended!',
            img: 'assets/images/people/2.jpg',
            name: 'Steven Porreca',
        },
        {
            review: 'I m so glad I found this barbershop! As someone with curly hair, finding a barber who knows how to handle it properly is not easy. But the barber here knew exactly what to do and gave me a fantastic curly haircut. They even gave me some useful tips on how to care for my curls at home.',
            img: 'assets/images/people/3.jpg',
            name: 'Steven Porreca',
        },
        {
            review: 'I brought my son to this barbershop for his first haircut, and they made it such a memorable experience for him! The barber was patient and gentle, and they had a special chair just for kids, which made him feel like a little prince. The haircut turned out adorable, and they even gave him a lollipop afterward.',
            img: 'assets/images/people/4.jpg',
            name: 'Steven Porreca',
        },
        {
            review: 'I have to say, this barbershop has the best customer service I ve ever experienced. From the moment I walked in, I was greeted with a smile and offered a beverage while I waited. The barbers are not only talented but also incredibly attentive.',
            img: 'assets/images/people/5.jpg',
            name: 'Steven Porreca',
        },
        {
            review: 'I ve been going to this barbershop for a long time, and I can confidently say it s the best in town. The barbers are not only skilled at their craft but also friendly and engaging. It feels more like catching up with old friends during my appointments. They also take walk-ins, and I ve never had to wait too long for a cut. If you want a great haircut in a warm and welcoming environment, look no further!',
            img: 'assets/images/people/6.jpg',
            name: 'Steven Porreca',
        },
    ];

    return (
        <section className="testimonial no-top" aria-label="section">
            <div className="container">
                <div className="row">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 2, 992: 3 }}>
                        <Masonry gutter="24px">
                            {TestimonialsData.map((item, index) => (
                                <div className="testi-box" key={index}>
                                    <div className="testi-cnt">
                                        <p>{item.review}</p>
                                        <div className="testi-user">
                                            <img className="rounded-circle" src={item.img} alt="testi" /><span>{item.name}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
                <div className="text-center">
                    <div className="spacer-single"></div>
                    <NavLink to="" className="btn-main">Load More Testimonials</NavLink>
                </div>
            </div>

        </section>
    );
}

export default TestimonialsBox;

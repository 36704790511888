import React, { useEffect } from 'react';
import { jarallax, jarallaxElement } from "jarallax";
import 'jarallax/dist/jarallax.css';
import 'jarallax/dist/jarallax.min.js';
import 'jarallax/dist/jarallax-element.min.js';
import "jquery-ui-dist/jquery-ui";

const WeReOpen = () => {

    jarallaxElement();

    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'));
    }, []);

    const WeReOpenData = [
        {
            count: '-50',
            heading: 'Were Open',
            title: 'Mon - Thu',
            content: '7:30AM - 6:30PM',
            title2: 'Friday',
            content2: '8:30AM - 8:30PM',
            title3: 'Sat - Sun',
            content3: '9:30AM - 5:30PM',
        },
        {
            count: '-100',
            heading: 'Location',
            title: 'Address',
            content: '5-Indraprasth Nagar, Above Kasturi Super Market, Nr. Satnam Hospital, Amin Marg Rajkot - 360001, Gujarat',
            title2: 'Phone',
            content2: '+91 94086 20201',
            title3: 'Email',
            content3: 'thecuttingbar2021@gmail.com',
        },
    ];

    return (
        <section className="we-re-open jarallax no-top">
            <div className="de-gradient-edge-top"></div>
            <img src="assets/images/background/1.jpg" className="jarallax-img" alt="" />
            <div className="container relative z1000">
                <div className="row gx-5">
                    {WeReOpenData.map((item, index) => (
                        <div className="col-lg-6 text-center jarallax" key={index} data-jarallax-element={item.count}>
                            <div className="d-sch-table">
                                <h2 className="wow fadeIn">{item.heading}</h2>
                                <div className="de-separator"></div>
                                <div className="d-col">
                                    <div className="d-title">{item.title}</div>
                                    <div className="d-content id-color">{item.content}</div>
                                </div>
                                <div className="d-col">
                                    <div className="d-title">{item.title2}</div>
                                    <div className="d-content id-color">{item.content2}</div>
                                </div>
                                <div className="d-col">
                                    <div className="d-title">{item.title3}</div>
                                    <div className="d-content id-color">{item.content3}</div>
                                </div>
                                <div className="d-deco"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
        </section>
    );
}

export default WeReOpen;
